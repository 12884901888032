import React, { Component } from "react";
import Layout from "../components/site/layout";
import SEO from "../components/site/seo";
import { StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBrowser } from "../services/helper";
import SliderGlide from "../components/site/slider-glide";
import { graphql } from "gatsby";

import * as styles from "../styles/index.module.scss";

export default class Amenagements extends Component {
  appearAnimation = [];

  constructor(props) {
    super(props);
    this.state = {
      zoomImage: "",
    };
    this.zoomImage = this.zoomImage.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  componentDidMount() {
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger);
    }

    this.appearAnimation.forEach((element) => {
      gsap.fromTo(element, { opacity: 0 }, { opacity: 1, scrollTrigger: element, duration: 1, ease: "Circ.easeIn" });
    });
  }

  zoomImage(name) {
    this.setState({ zoomImage: name });
  }

  zoomOut(e) {
    this.setState({ zoomImage: "" });
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile(filter: { absolutePath: { regex: "/amenagements/" } }) {
              edges {
                node {
                  name
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <Layout currentPage="about">
            <SEO
              title="A propos des aménagements"
              description="Aménagements des écuries du chalet - centre équestre situé à Annecy Epagny. 
            Visionner le magnifique cadre dans lequel vous pouvez apprendre ou progresser à cheval. 
            Pensions pour chevaux, cours du débutant au confirmer, balades dans la mandallaz ... 
            A 2 minutes du centre d'Epagny, 10 minutes d'Annecy et 40 de Genève en Suisse.
            Compétitions, cours particuliers et collectifs, le poney club contient tout ce qu'il vous faut alors venir le visiter :)"
            />

            <div className="container">
              <div
                className="row small-inversed"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-8">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter((e) => e.node.name === "entree manege")[0].node.childImageSharp
                        .gatsbyImageData
                    }
                    alt="Annecy Epagny écuries du Chalet"
                  />
                </div>
                <div className="col-md-4 m-auto">
                  <h3>Des installations de qualité</h3>
                  <p>
                    Venez monter à cheval dans un lieu moderne et agréable. Profitez de notre manège et de la carrière.
                  </p>
                </div>
              </div>
              <hr />
              <div
                className="row"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-4 m-auto">
                  <h3>Un environnement bénéfique</h3>
                  <p>Découvrez les balades à cheval dans la Mandallaz qui entoure Epagny.</p>
                  <p>
                    En effet, au coeur d'Epagny, à 15 minutes d'Annecy et à 40 minutes de Genève, le centre est
                    idéalement situé pour que tout le monde puisse en profiter.
                  </p>
                </div>
                <div className="col-md-8 rounded">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter((e) => e.node.name === "annecy")[0].node.childImageSharp
                        .gatsbyImageData
                    }
                    alt="Les écuries du Chalet à Annecy en Haute savoie"
                  />
                </div>
              </div>
              <hr />
              <div
                className="row small-inversed"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-8 rounded">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter((e) => e.node.name === "centre equestre vue relief")[0].node
                        .childImageSharp.gatsbyImageData
                    }
                    alt="Les écuries du Chalet"
                  />
                </div>
                <div className="col-md-4 m-auto">
                  <h3>Respecter le cheval</h3>
                  <p>
                    Cela me tient à coeur de laisser nos chevaux en liberté afin qu'ils puissent s'épanouir lorsqu'ils
                    ne sont pas travaillés. C'est pourquoi ils passent la majeure partie de leur temps dans des prés ou
                    des paddocks.
                  </p>
                </div>
              </div>
              <hr />
            </div>

            <div style={{ backgroundColor: "#000" }}>
              <h3 className="pv-xs" style={{ color: "#fff", textAlign: "center" }}>
                Galerie de photos des écuries du Cha'let
              </h3>
              <div className="container-fluid" style={{ display: "flex", flexWrap: "wrap" }}>
                {data.allFile.edges.map((media) => {
                  media = media.node;
                  return (
                    <div
                      className="col-md-4"
                      style={{ paddingBottom: "30px" }}
                      ref={(div) => {
                        this.appearAnimation.push(div);
                      }}
                      onClick={(e) => this.zoomImage(media.name)}
                    >
                      {media.childImageSharp ? (
                        <GatsbyImage
                          className={styles.imageGallery}
                          image={media.childImageSharp.gatsbyImageData}
                          alt={media.name}
                        />
                      ) : (
                        <video
                          className={styles.imageGallery}
                          alt={media.name}
                          loop
                          src={media.publicURL}
                          type="video/mp4"
                          preload="auto"
                          autoPlay
                        ></video>
                      )}
                      {this.state.zoomImage === media.name && (
                        <div className={styles.imageZoomContainer} onClick={this.zoomOut}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {media.childImageSharp ? (
                              <GatsbyImage
                                image={media.childImageSharp.gatsbyImageData}
                                alt={media.name}
                                style={{ width: "100%" }}
                              />
                            ) : (
                              <video
                                style={{ width: "100%" }}
                                alt={media.name}
                                loop
                                src={media.publicURL}
                                type="video/mp4"
                                preload="auto"
                                autoPlay
                              ></video>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </Layout>
        )}
      />
    );
  }
}
